<template>
  <div class="flex gap-x-8 xs:gap-x-12 w-full">
    <se-advisor-chat-option
      :advisor="advisor"
      :disabled="!advisor.video_enabled"
      :price="parseFloat(advisor.per_minute_fee_video, 10)"
      color-scheme="primary"
      @option-clicked="advisor.video_enabled && startChat('video')"
    >
      <template #title>
        Video
      </template>
      <template #button>
        <se-icon name="video" :size="16" class="!mr-4" />
        Start
      </template>
    </se-advisor-chat-option>

    <se-advisor-chat-option
      :advisor="advisor"
      :disabled="!advisor.audio_enabled"
      :price="parseFloat(advisor.per_minute_fee_audio, 10)"
      color-scheme="feature"
      @option-clicked="advisor.audio_enabled && startChat('audio')"
    >
      <template #title>
        Voice
      </template>
      <template #button>
        <se-icon name="phone" :size="16" class="!mr-4" />
        Call
      </template>
    </se-advisor-chat-option>

    <se-advisor-chat-option
      :advisor="advisor"
      :disabled="!advisor.text_enabled"
      :price="parseFloat(advisor.per_minute_fee_text, 10)"
      color-scheme="love"
      @option-clicked="advisor.text_enabled && startChat('text')"
    >
      <template #title>
        Chat
      </template>
      <template #button>
        <se-icon name="message-circle" :size="16" class="!mr-4" />
        Text
      </template>
    </se-advisor-chat-option>
  </div>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { getGlobalProperties } from '@/src/shared/helpers/getGlobalProperties';
import { chatStore } from '@/src/store/chat';
import { usersStore } from '@/src/store/users';
import SeIcon from '@/src/shared/ui-kit/icon/Icon';
import SeAdvisorChatOption from './advisor-chat-option';

const props = defineProps<{
  advisor: any
}>();

const { $toast } = getGlobalProperties();

const users = usersStore();
const { isAdvisor } = storeToRefs(users);

const { forceStartChat } = chatStore();
const startChat = (chatMode: string) => {
  if (isAdvisor.value) {
    $toast({
      message: 'Unable to chat with another advisor',
      type: 'error',
    });
  } else {
    forceStartChat(props.advisor, chatMode);
  }
};
</script>

<script lang="ts">
export default {
  name: 'SeAdvisorChatOptions',
};
</script>
