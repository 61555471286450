<template>
  <article class="bg-neutral-10 rounded-12 p-[8px] xs:p-12 pb-16 w-full flex flex-col">
    <p class="text-center text-h-m font-semibold !pb-4" :class="enabled ? 'text-black' : 'text-neutral-50'">
      <slot name="title" />
    </p>
    <div class="pb-10 xs:pb-20">
      <p v-if="advisor.discount && enabled" class="text-center text-b-s font-medium text-neutral-50">
        <s>${{ formatPrice(+props.price) }}</s>
      </p>
      <p class="text-center text-b-s font-bold" :class="enabled ? scheme[props.colorScheme].text : 'text-neutral-40'">
          <span v-if="!enabled" class="mt-[8px] xs:mt-[24px] block leading-16">Not <br class="xs:hidden"/> Available</span>
          <span v-else>${{ formatPrice(discountedPrice) }}/min</span>
      </p>
    </div>
    <se-button size="small" class="w-full" :disabled="!enabled" :class="enabled ? scheme[props.colorScheme].bg : ''" @click.prevent="emits('optionClicked')">
      <slot name="button" />
    </se-button>
  </article>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { AdvisorService } from '@/src/modules/advisor/services/advisor.service';
import SeButton from '@/src/shared/ui-kit/button/Button';

const emits = defineEmits<{
  optionClicked: any
}>();

const props = defineProps<{
  advisor: any,
  colorScheme: string;
  disabled: boolean;
  price: number;
}>();

const scheme = {
  primary: {
    bg: '!bg-primary-100',
    text: 'text-primary-100',
  },
  feature: {
    bg: '!bg-feature-100',
    text: 'text-feature-100',
  },
  love: {
    bg: '!bg-love-100',
    text: 'text-love-100',
  },
};

const isOnline = computed(() => props.advisor.status === 'online');

const enabled = computed(() => isOnline.value && !props.disabled);

const discountedPrice = computed(() => AdvisorService.getDiscountedPrice(props.price, props.advisor));

const formatPrice = (price: number): string => price.toFixed(2);
</script>

<script lang="ts">
export default {
  name: 'SeAdvisorChatOption',
};
</script>
