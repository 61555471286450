<template>
  <div
    class="c-badge"
    :class="[
      `c-badge--${props.type}`,
    ]"
  >
    <slot />
  </div>
</template>

<script setup lang="ts">
import { BadgeType } from './types/BadgeType';

const props = withDefaults(defineProps<{
  type?: BadgeType,
}>(), {
  type: 'default',
});
</script>

<script lang="ts">
export default {
  name: 'SeBadge',
};
</script>
